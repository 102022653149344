import React from "react"
import {Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"


const ComboFlynow = () => {
  const data = {
    id: "combo_apps_ebooks_cursos",
    title: "3 APPS + 3 EBOOKS + 2 CURSOS",
    discontPercentage: 50,
    URL_CHECKOUT: "https://buy.stripe.com/8wMeWHg0N19Hg7K3cD?locale=pt-BR&prefilled_promo_code=COMBO50",
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVOS (PLANO PREMIUM VITALÍCIO)",
        data: Products.apps
      },
      {
        section: "EBOOKS",
        data: Products.ebooks
      },
      {
        section: "CURSOS",
        data: Products.cursos
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow
